export default [
  {
    name: "help",
    title: "Пошук послуг",
    message: [
      "Введіть у рядку пошуку ключове слово чи словосполучення із назви послуги, яку ви шукаєте. Наприклад, «місце проживання» чи «прописка»",
      " У результатах пошуку відшукайте необхідну вам послугу або ж знайдіть її у життєвій ситуації, яку вам пропонує результат пошуку",
    ],
  },
  {
    name: "error",
    title: "Помилка",
    message: ["Виникла помилка при підключенні бази документів, або документи відсутні.", "Спробуйте пізніше"],
  },
];
