export default {
  all: {
    1: "https://sheets.googleapis.com/v4/spreadsheets/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/values/Cons?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    2: "https://sheets.googleapis.com/v4/spreadsheets/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/values/Ris?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    3: "https://sheets.googleapis.com/v4/spreadsheets/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/values/Des?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    4: "https://sheets.googleapis.com/v4/spreadsheets/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/values/First?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    5: "https://sheets.googleapis.com/v4/spreadsheets/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/values/Proj?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    6: "https://sheets.googleapis.com/v4/spreadsheets/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/values/Proj?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    7: "https://sheets.googleapis.com/v4/spreadsheets/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/values/Meet?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    8: "https://sheets.googleapis.com/v4/spreadsheets/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/values/Prot?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    9: "https://sheets.googleapis.com/v4/spreadsheets/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/values/Prot?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    10: "https://sheets.googleapis.com/v4/spreadsheets/13ZoSf9b-cZQE-XZqC4rQiiCjTZ3EYkgD0CXNQxVZfSs/values/Pass?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    11: "https://sheets.googleapis.com/v4/spreadsheets/13ZoSf9b-cZQE-XZqC4rQiiCjTZ3EYkgD0CXNQxVZfSs/values/Rep?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    12: "https://sheets.googleapis.com/v4/spreadsheets/13ZoSf9b-cZQE-XZqC4rQiiCjTZ3EYkgD0CXNQxVZfSs/values/Req?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    13: "https://sheets.googleapis.com/v4/spreadsheets/1sQkiNQP-JJn2-kmQpQUv5cBj3n4Fos1eKJgDyLyL3Bk/values/Ans?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    14: "https://sheets.googleapis.com/v4/spreadsheets/1T5RhhA9hVGJEuXiqTI20eUDcbz3ieStq5ApuXNyjx-Y/values/Ans?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    15: "https://sheets.googleapis.com/v4/spreadsheets/1RzyW6u7yiIqvjbdA6WvFEII6JbxHISAKcYwszpsGm0U/values/Ans?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
    16: "https://sheets.googleapis.com/v4/spreadsheets/1zRzMvl7nxwg2-i1V-X3fGiYSfMJB-KoQDYZ3VcSybYA/values/Ans?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
  },
  //   all: {
  //     1: "https://spreadsheets.google.com/feeds/list/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/6/public/values?alt=json",
  //     2: "https://spreadsheets.google.com/feeds/list/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/5/public/values?alt=json",
  //     3: "https://spreadsheets.google.com/feeds/list/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/4/public/values?alt=json",
  //     4: "https://spreadsheets.google.com/feeds/list/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/7/public/values?alt=json",
  //     5: "https://spreadsheets.google.com/feeds/list/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/6/public/values?alt=json",
  //     6: "https://spreadsheets.google.com/feeds/list/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/5/public/values?alt=json",
  //     7: "https://spreadsheets.google.com/feeds/list/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/2/public/values?alt=json",
  //     8: "https://spreadsheets.google.com/feeds/list/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/3/public/values?alt=json",
  //     9: "https://spreadsheets.google.com/feeds/list/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/3/public/values?alt=json",
  //     10: "https://spreadsheets.google.com/feeds/list/13ZoSf9b-cZQE-XZqC4rQiiCjTZ3EYkgD0CXNQxVZfSs/1/public/values?alt=json",
  //     11: "https://spreadsheets.google.com/feeds/list/13ZoSf9b-cZQE-XZqC4rQiiCjTZ3EYkgD0CXNQxVZfSs/2/public/values?alt=json",
  //     12: "https://spreadsheets.google.com/feeds/list/13ZoSf9b-cZQE-XZqC4rQiiCjTZ3EYkgD0CXNQxVZfSs/3/public/values?alt=json",
  //     13: "https://spreadsheets.google.com/feeds/list/1sQkiNQP-JJn2-kmQpQUv5cBj3n4Fos1eKJgDyLyL3Bk/3/public/values?alt=json",
  //     14: "https://spreadsheets.google.com/feeds/list/1T5RhhA9hVGJEuXiqTI20eUDcbz3ieStq5ApuXNyjx-Y/1/public/values?alt=json",
  //     15: "https://spreadsheets.google.com/feeds/list/1RzyW6u7yiIqvjbdA6WvFEII6JbxHISAKcYwszpsGm0U/1/public/values?alt=json",
  //     16: "https://spreadsheets.google.com/feeds/list/1zRzMvl7nxwg2-i1V-X3fGiYSfMJB-KoQDYZ3VcSybYA/1/public/values?alt=json",
  //   },

  last: [
    {
      url:
        "https://sheets.googleapis.com/v4/spreadsheets/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/values/Ris?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
      key: 2,
      id: 1,
    },
    {
      url:
        "https://sheets.googleapis.com/v4/spreadsheets/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/values/Des?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
      key: 3,
      id: 2,
    },
    {
      url:
        "https://sheets.googleapis.com/v4/spreadsheets/1CwNjSWnwx6Xqgg4SyMr33KAPUJcsbiViMGmiIDrmvq8/values/Proj?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
      key: 5,
      id: 3,
    },
    {
      url:
        "https://sheets.googleapis.com/v4/spreadsheets/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/values/Proj?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
      key: 6,
      id: 4,
    },
    {
      url:
        "https://sheets.googleapis.com/v4/spreadsheets/1sLQ1_e_eXKVQilUnJVNCFbimI71Ey0GBYix9NWHKuwg/values/Cons?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas",
      key: 1,
      id: 5,
    },
  ],

  url: [
    "/vk/rozporyadzhennya/",
    "/rada/rishennya/",
    "/vk/rishennya/",
    "/rada/old/",
    "/rada/proektu/",
    "/vk/proektu/",
    "/rada/reyestratsiya/;/rada/holosuvannya/;/rada/protokolu/;/rada/rada/",
    "/vk/protokolu/",
    "/rada/komisiyi/",
    "/budget/pasportu/",
    "/budget/zvitu/",
    "/budget/zaputu/",
  ],

  structures: [
    "Виконавчий комітет Бердичівської міської ради",
    "Відділ комунальної власності",
    "Відділ культури Бердичівської міської ради",
    "Відділ охорони здоров'я Бердичівської міської ради",
    "Управління житлово-комунального господарства",
    "Управління освіти і науки Бердичівської міської ради",
    "Управління сім'ї та соціального захисту населення Бердичівської міської ради",
    "Відділ молоді та спорту Бердичівської міської ради",
    "Фінансове управління Бердичівської міської ради",
    "Управління містобудування, архітектури та земельних відносин Бердичівскої міської ради",
    "Управління економічного розвитку Бердичівської міської ради",
  ],
};
