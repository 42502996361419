import dates from "../../store/lists/dates";

export function inLink(c, n, d, i) {
  if (c == 1 || c == 3 || c == 6 || c == 8) {
    return `/document?category=${c}&number=${n}-${d.slice(-2)}`;
  } else if (c == 9) {
    return `/document?category=${c}&number=${n}-${i}`;
  } else {
    return `/document?category=${c}&number=${n}`;
  }
}

export function outLink(c, n, d, i) {
  let timestamp = Date.now().toLocaleString();

  if (c == 1 || c == 3 || c == 6 || c == 8) {
    return `${dates.url[c - 1]}${n}-${d.slice(-2)}.pdf?s=${timestamp}`;
  } else if (c == 7) {
    let data = [];
    dates.url[c - 1].split(";").forEach((e) => {
      data.push(`${e}${n}.pdf?s=${timestamp}`);
    });
    return data;
  } else if (c == 9) {
    return `${dates.url[c - 1]}${n}-${i}.pdf?s=${timestamp}`;
  } else {
    return `${dates.url[c - 1]}${n}.pdf?s=${timestamp}`;
  }
}
