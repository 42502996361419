import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/documents",
    name: "Documents",
    meta: { title: "Категорія" },
    component: () => import("../views/Documents.vue"),
  },
  {
    path: "/document",
    name: "Document",
    meta: { title: "Документ" },
    component: () => import("../views/Document.vue"),
  },
  {
    path: "/search",
    name: "Search",
    meta: { title: "Пошук" },
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Missing",
    meta: { title: "404" },
    component: () => import("../views/Missing.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title + " | Документи Бердичів" : "Документи | Бердичів";
  next();
});

export default router;
