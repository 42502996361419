<template>
  <div class="hero">
    <div class="hero-title">
      <h3>Пошук документів</h3>
    </div>
    <div class="hero-search">
      <div class="hero-input">
        <input
          type="text"
          placeholder="Номер або назва документу"
          name="search"
          id="search"
          v-model="query"
          v-on:keyup.enter="submit"
        />
        <router-link :to="'/search?query=' + query">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 513.28 513.28"
          >
            <path
              d="M495.04,404.48L410.56,320c15.36-30.72,25.6-66.56,25.6-102.4C436.16,97.28,338.88,0,218.56,0S0.96,97.28,0.96,217.6
			s97.28,217.6,217.6,217.6c35.84,0,71.68-10.24,102.4-25.6l84.48,84.48c25.6,25.6,64,25.6,89.6,0
			C518.08,468.48,518.08,430.08,495.04,404.48z M218.56,384c-92.16,0-166.4-74.24-166.4-166.4S126.4,51.2,218.56,51.2
			s166.4,74.24,166.4,166.4S310.72,384,218.56,384z"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";
  import { useRouter } from "vue-router";

  export default {
    name: "Hero",

    setup() {
      const router = useRouter();
      const query = ref("");

      const submit = () => {
        router.push({ path: "search", query: { query: query.value } });
      };
      return { query, submit };
    },
  };
</script>

<style lang="scss">
  .hero {
    position: relative;
    display: grid;
    justify-items: center;
    grid-gap: 30px;
    width: 100%;
    padding: 10px;
    min-height: 640px;
    margin-top: -100px;
    background-image: linear-gradient(135deg, #43cbff 10%, #9708cc 100%);

    .hero-title {
      text-align: center;
      align-self: end;

      h3,
      h4 {
        text-transform: uppercase;
        padding: 0;
        margin: 0;
      }
    }
    .hero-search {
      position: relative;
      display: block;
      width: 100%;
      max-width: 520px;

      .hero-input {
        position: relative;
        display: flex;
        padding: 7px 54px 7px 12px;
        border-radius: 999px;
        background: #fff;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

        a {
          position: absolute;
          display: block;
          width: 48px;
          height: 48px;
          top: 3px;
          right: 3px;
          border-radius: 50%;
          background: #fff;

          &:hover {
            background: $acs;

            svg {
              fill: #fff;
            }
          }

          svg {
            width: 22px;
            height: 22px;
            margin: 13px;
            fill: $black;
          }
        }
      }
    }
  }
</style>
