<template>
  <div class="last">
    <div class="section">
      <h3>Останні документи</h3>
      <Loader v-if="load" />
      <div class="wrap" v-else>
        <div class="column" v-for="(item, index) in last" :key="index">
          <ul>
            <h5 class="title">{{ item.name }}</h5>
            <li v-for="(d, endex) in item.data" :key="endex">
              <a :href="getLink(item.category, d.номер, d.дата || d.опубліковано || d.прийнято)">{{ d.назва }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { useStore } from "vuex";
  import { ref, reactive, onBeforeMount } from "vue";
  import { inLink } from "../../components/functions/link";

  import axios from "axios";
  import dates from "../../store/lists/dates";

  import Loader from "../global/Loader.vue";
  export default {
    name: "Last",

    components: { Loader },

    setup() {
      const store = useStore();

      const getLink = (c, n, d) => inLink(c, n, d);
      const last = reactive([
        {
          name: "Рішення міської ради",
          category: 2,
          data: [],
        },
        {
          name: "Рішення виконкому",
          category: 3,
          data: [],
        },
        {
          name: "Проекти рішень міської ради",
          category: 5,
          data: [],
        },
        {
          name: "Проекти рішень виконкому",
          category: 6,
          data: [],
        },
        {
          name: "Розпорядження міського голови",
          category: 1,
          data: [],
        },
      ]);

      onBeforeMount(() => fetchLast());

      //Fetch data

      const load = ref(true);

      const fetchLast = async () => {
        try {
          let counter = 0;

          dates.last.forEach(async (e, i) => {
            let get = await axios.get(e.url),
              header = get.data.values[0],
              rows = get.data.values.slice(1),
              formated = [];

            for (const row of rows) {
              const formattedRow = {};

              header.forEach((e, i) => {
                formattedRow[e.toLowerCase()] = row[i];
              });

              formated.push(formattedRow);
            }

            last[i].data = [...formated.splice(-5)].reverse();
            counter += 1;
            if (counter == 5) load.value = false;
          });
        } catch (e) {
          store.commit("setModal", "error");
          console.log(e);
        }
      };

      return { last, load, getLink };
    },
  };
</script>
<style lang="scss">
  .last {
    background: $acs-trans;

    .wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media screen and (max-width: 968px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 585px) {
        grid-template-columns: 1fr;
      }

      .column {
        .title {
          position: relative;
          display: inline-block;

          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            bottom: -0.3em;
            left: 0;
            background-image: linear-gradient(135deg, #43cbff 10%, #9708cc 100%);
          }
        }
        ul {
          li a {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
</style>
