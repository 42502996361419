import { createStore } from "vuex";

export default createStore({
  state: {
    modal: null,
  },
  mutations: {
    setModal(state, payload) {
      state.modal = payload;
    },
  },
  actions: {},
  modules: {},
});
