<template>
  <div class="home">
    <Hero />
    <Menu />
    <Last />
  </div>
</template>

<script>
  import Menu from "../components/home/Menu.vue";
  import Hero from "../components/home/Hero.vue";
  import Last from "../components/home/Last.vue";

  export default {
    name: "Home",
    components: { Hero, Menu, Last },
  };
</script>
