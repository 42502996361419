<template>
  <div class="header" :class="{ more: isMore, mobile: isMenu }" @click="onHeader($event)">
    <div class="header-mobile" :class="{ active: isMenu }">
      <div class="mobile-menu">
        <input
          type="search"
          placeholder="Пошук..."
          name="search"
          id="search"
          v-model="query"
          v-on:keyup.enter="submit"
        />

        <ul>
          <li>
            <router-link to="/" @click="onChange">Головна</router-link>
          </li>
          <li>
            <a class="icon" :class="{ active: isMore }" @click="isMore = !isMore">
              <span>Категорії</span>
              <svg viewBox="0 0 451.847 451.847">
                <path
                  d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                />
              </svg>
            </a>
          </li>
          <li>
            <router-link to="/search" @click="onChange">Пошук</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="header-more" :class="{ active: isMore }">
      <div class="more-menu">
        <ul v-for="(cat, index) in menu.main" :key="index">
          <p class="title">{{ cat.title }}</p>
          <li v-for="(item, index) in cat.data" :key="index">
            <router-link :to="`/documents?category=${item.url}`" @click="onChange">
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="header-bar">
      <router-link class="logo" to="/">
        <p>Документи</p>
      </router-link>

      <ul class="main-menu">
        <li>
          <router-link to="/" @click="onChange">Головна</router-link>
        </li>
        <li>
          <a class="icon" :class="{ active: isMore }" @click="isMore = !isMore">
            <span>Категорії</span>
            <svg viewBox="0 0 451.847 451.847">
              <path
                d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
              />
            </svg>
          </a>
        </li>
        <li>
          <router-link to="/search" @click="onChange">Пошук</router-link>
        </li>
      </ul>

      <div class="burger-btn" :class="{ active: isMenu }" @click="(isMenu = !isMenu), (isMore = false)">
        <svg viewBox="0 0 100 100">
          <path
            class="line line1"
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          />
          <path class="line line2" d="M 20,50 H 80" />
          <path
            class="line line3"
            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  import menu from "../../store/lists/menu";

  import { useRouter } from "vue-router";

  import { ref } from "vue";

  export default {
    name: "Header",

    setup() {
      const router = useRouter();
      const isMenu = ref(false);
      const isMore = ref(false);

      const onChange = () => {
        setTimeout(() => {
          isMore.value = false;
          isMenu.value = false;
        }, 100);
      };

      const onHeader = (e) => {
        if (e.target.classList.contains("more")) {
          isMore.value = false;
          isMenu.value = false;
        }
      };

      const query = ref("");

      const submit = () => {
        router.push({ path: "search", query: { query: query.value } });
        isMore.value = false;
        isMenu.value = false;
      };

      return { menu, isMenu, isMore, onChange, onHeader, query, submit };
    },
  };
</script>

<style lang="scss">
  .header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transition: 0s;

    &.more,
    &.mobile {
      position: fixed;
      bottom: 0;
      background: rgba($color: #000000, $alpha: 0.5);
      transition: background 0.5s 0.3s linear;

      .header-bar::after {
        width: 100%;
        transition-delay: 0.3s;
      }
    }

    .header-more {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: $light-grey;
      visibility: hidden;
      transform: translate3d(0, -100%, 0);
      transition: transform 700ms cubic-bezier(0.75, 0, 0, 1), visibility 700ms linear;

      &.active {
        visibility: visible;
        transform: translate3d(0, 0, 0);
      }

      .more-menu {
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        width: 100%;
        max-width: 1680px;
        margin: 100px auto 0;
        padding: 1em;
        font-size: 0.8em;
        font-weight: 400;

        .title {
          color: $mid-grey;
          font-weight: 500;
        }
      }
    }

    .header-mobile {
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      right: 0;
      padding: 110px 1em 1em;
      background: $light-grey;
      visibility: hidden;
      transform: translate3d(0, -100%, 0);
      transition: transform 700ms cubic-bezier(0.75, 0, 0, 1), visibility 700ms linear;

      @media screen and (max-width: 721px) {
        display: block;
      }

      &.active {
        visibility: visible;
        transform: translate3d(0, 0, 0);
      }

      .mobile-menu {
        input {
          border-bottom: 1px solid #000;
        }

        ul {
          display: grid;
          margin: 1em 0;
        }
      }
    }

    .header-bar {
      position: relative;
      display: grid;
      grid-template-columns: 200px 1fr 200px;
      align-items: center;
      grid-gap: 1em;
      width: 100%;
      max-width: 1680px;
      height: 100px;
      margin: 0 auto;
      padding: 0 1em;

      @media screen and (max-width: 721px) {
        grid-template-columns: 100px 1fr;
      }

      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 2px;
        left: 0;
        bottom: 0;
        background: $black;
        transition: width 300ms linear;
      }

      .logo {
        position: relative;
        display: grid;
        place-items: center;

        &:hover {
          color: $black;
        }

        p {
          position: relative;
          display: block;
          margin: 0;
          font-size: 1.2em;
          font-weight: 600;

          &::after {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            bottom: 12px;
            right: -12px;
            background: $acs;
            border-radius: 50%;
            border: 0.5px solid #fff;
          }
        }
      }

      .main-menu {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 721px) {
          display: none;
        }

        a {
          position: relative;
          margin: 0 0.5em;
          font-size: 0.8em;
          font-weight: 500;

          &:hover {
            color: $black;

            &::after {
              width: 100%;
              left: 0;
            }
          }

          &::after {
            position: absolute;
            content: "";
            width: 0;
            height: 2px;
            left: 50%;
            bottom: -3px;
            background: $black;
            transition: 0.2s ease;
          }
        }
      }

      .burger-btn {
        display: none;
        justify-self: end;
        width: 50px;
        height: 50px;

        @media screen and (max-width: 721px) {
          display: block;
        }

        svg {
          width: 100%;
          height: 100%;
        }

        &.active {
          .line1 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -141;
          }
          .line2 {
            stroke-dasharray: 0 60;
            stroke-dashoffset: -60;
          }
          .line3 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -141;
          }
        }

        .line {
          fill: none;
          stroke: black;
          stroke-linecap: round;
          stroke-width: 4;
          transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
            stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

          &.line1 {
            stroke-dasharray: 60 207;
          }
          &.line2 {
            stroke-dasharray: 60 60;
          }
          &.line3 {
            stroke-dasharray: 60 207;
          }
        }
      }
    }
  }
</style>
