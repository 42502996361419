export default {
  main: [
    {
      title: "Міська рада",
      data: [
        { title: "Рішення міської ради", url: 2 },
        { title: "Рішення попередніх скликань", url: 4 },
        { title: "Проєкти рішень міської ради", url: 5 },
        { title: "Засідання міської ради", url: 7 },
        { title: "Протоколи комісій", url: 9 },
      ],
    },
    {
      title: "Виконавчий комітет",
      data: [
        { title: "Рішення виконавчого комітету", url: 3 },
        { title: "Проєкти рішень виконавчого комітету", url: 6 },
        { title: "Розпорядження міського голови", url: 1 },
        { title: "Засідання виконавчого комітету", url: 8 },
      ],
    },
    {
      title: "Бюджет",
      data: [
        { title: "Паспорти бюджетних програм", url: 10 },
        { title: "Звіти виконання бюджетних програм", url: 11 },
        { title: "Запити ", url: 12 },
      ],
    },
    {
      title: "Містобудівна діяльність",
      data: [
        { title: "Реєстр містобудівних умов та обмежень", url: 13 },
        { title: "Реєстр присвоєння адрес", url: 14 },
        { title: "Реєстр будівельних паспортів", url: 15 },
      ],
    },
    {
      title: "Інше",
      data: [{ title: "Реєстр міжгалузевих угод", url: 16 }],
    },
  ],

  name: [
    { title: "Рішення міської ради", url: 2 },
    { title: "Рішення попередніх скликань", url: 4 },
    { title: "Проєкти рішень міської ради", url: 5 },
    { title: "Засідання міської ради", url: 7 },
    { title: "Протоколи комісій", url: 9 },
    { title: "Рішення виконавчого комітету", url: 3 },
    { title: "Проєкти рішень виконавчого комітету", url: 6 },
    { title: "Розпорядження міського голови", url: 1 },
    { title: "Засідання виконавчого комітету", url: 8 },
    { title: "Паспорти бюджетних програм", url: 10 },
    { title: "Звіти виконання бюджетних програм", url: 11 },
    { title: "Запити ", url: 12 },
    { title: "Реєстр містобудівних умов та обмежень", url: 13 },
    { title: "Реєстр присвоєння адрес", url: 14 },
    { title: "Реєстр будівельних паспортів", url: 15 },
    { title: "Реєстр міжгалузевих угод", url: 16 },
  ],
};
