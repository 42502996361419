<template>
  <transition name="fade">
    <div class="modal" v-if="isModal != null">
      <div class="modal-wrap">
        <div class="modal-header">
          <h3>{{ data.title }}</h3>

          <div class="close" @click="modal">
            <svg viewBox="0 0 47.971 47.971">
              <path
                d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
		c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
		C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
		s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
              />
            </svg>
          </div>
        </div>

        <div class="modal-body">
          <p v-for="(item, index) in data.message" :key="index">
            {{ item }}
          </p>
        </div>
        <a @click="modal" class="button">Зрозуміло</a>
      </div>
    </div>
  </transition>
</template>
<script>
  import { useStore } from "vuex";

  import modals from "../../store/lists/modals";

  import { computed } from "vue";

  export default {
    name: "Modal",

    setup() {
      const store = useStore();
      const isModal = computed(() => store.state.modal);

      const modal = () => {
        store.commit("setModal", null);
      };

      const data = computed(() => modals.find((e) => e.name === isModal.value));

      return { isModal, modal, data };
    },
  };
</script>
<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .modal {
    position: fixed;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2vw;
    background: rgba($color: #fff, $alpha: 0.3);
    backdrop-filter: blur(5px);
    z-index: 1001;

    .modal-wrap {
      position: relative;
      width: 100%;
      min-width: 220px;
      max-width: 640px;
      min-height: 160px;
      padding: 3vw;
      background: #fff;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;

      .modal-header {
        display: grid;
        grid-template-columns: 1fr 30px;
        align-items: center;
        grid-gap: 1em;

        h3 {
          margin: 0;
          color: $mid-grey;
          font-weight: 500;
        }

        .close {
          position: relative;
          display: block;
          width: 30px;
          height: 30px;
          place-self: center;

          svg {
            position: relative;
            width: 18px;
            height: 18px;
            margin: 6px;
            fill: $blue;
          }
        }

        .close {
          border-radius: 50%;
          cursor: pointer;

          svg {
            fill: $black;
          }

          &:hover svg {
            fill: $acs;
          }
        }
      }

      .modal-body {
        margin: 32px 0;
      }
    }
  }
</style>
